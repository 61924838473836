import React from 'react';

import Layout from '../../components/layout';

const Team = () => (
  <Layout>
    <div>
      <h1>
        The Team is a Dog!!!
      </h1>
      <p>Bow wow wow!!</p>
    </div>
  </Layout>
);

export default Team;
